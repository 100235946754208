<template>
    <form @submit.prevent="submitHandler">
        <recess-modal :show-modal="isModalVisible" @close="close">
            <template slot="modal-title">
                <h3>{{ PROVIDER.Order.Shared.Popups.AcceptRejectOrder.RejectOrder.Title }}</h3>
            </template>

            <template slot="modal-body">
                <div class="d-flex justify-content-start align-items-center flex-wrap mb-2">
                    <label :class="['w-30', cancellationReasonError ? 'align-self-start' : '']">{{
                        PROVIDER.Order.Shared.Popups.AcceptRejectOrder.RejectOrder.CancellationReason.Label
                    }}</label>
                    <recess-multi-select
                        :selected-options="orderStatusTransitionItem.cancellationReason"
                        :options="getCancellationReasons()"
                        :single-select="true"
                        :search-input="true"
                        :error-message="cancellationReasonError"
                        :placeholder="PROVIDER.Order.Shared.Popups.AcceptRejectOrder.RejectOrder.CancellationReason.Placeholder"
                        class="w-70 qa-rejection-reason-select"
                        @input="(newValue) => setCancellationReason(newValue)"
                        @blur="$v.orderStatusTransitionItem.cancellationReason.$touch()"
                    />
                </div>
                <recess-textarea-input
                    v-model="orderStatusTransitionItem.message"
                    :label-text="PROVIDER.Order.Shared.Popups.AcceptRejectOrder.RejectOrder.CancellationMessage.Label"
                    :error-message="cancellationMessageError"
                    class="qa-rejection-optional-input mt-4"
                    rows="15"
                    maxlength="2000"
                    @blur="$v.orderStatusTransitionItem.message.$touch()"
                />
            </template>

            <template slot="modal-footer">
                <div class="d-flex justify-content-end">
                    <recess-button
                        variant="tertiary"
                        :title="BUTTON_TEXT.cancel"
                        class="qa-legal-company-cancel-button"
                        @click.native.prevent="close()"
                    />
                    <recess-button
                        variant="secondary"
                        type="submit"
                        :title="BUTTON_TEXT.submit"
                        class="ml-3 qa-legal-company-reject-button"
                    />
                </div>
            </template>
        </recess-modal>
    </form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import { getEnumList } from '@/../../shared/api/SharedClient'
import { API_CALL_URL_PATHS, BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { isRequiredErrorMessage } from '../../../../shared/constants/validationMessageHelper.js'

export default {
    name: 'RejectOrderPopupComponent',
    mixins: [validationMixin],
    props: {
        isModalVisible: {
            type: Boolean,
            default: false
        },
        isPurchaseAgreementOrder: {
            type: Boolean,
            default: false
        },
        cancellationType: {
            type: String,
            default: 'RejectedProvider'
        }
    },
    validations: {
        orderStatusTransitionItem: {
            cancellationReason: { required },
            message: { required }
        }
    },
    data() {
        return {
            PROVIDER,
            BUTTON_TEXT,
            purchaseAgreementCancellationReasons: null,
            courseCancellationReasons: null,
            providerCancellationReasons: null,
            orderStatusTransitionItem: null
        }
    },
    computed: {
        cancellationReasonError() {
            return isRequiredErrorMessage('cancellationReason', 'Reden voor annulering', this.$v.orderStatusTransitionItem.cancellationReason)
        },
        cancellationMessageError() {
            return isRequiredErrorMessage('message', 'Bericht voor annulering', this.$v.orderStatusTransitionItem.message)
        }
    },
    // reset data in mValue & isTouched whenever the pop up is closed by watching the value
    // change of the props of isModalvisible from its parent 'OrderList'
    watch: {
        isModalVisible: async function watchPropsChange(newValue) {
            if (newValue === false) {
                this.$v.$reset()
                this.setInitialOrderStatusTransition()
            }
        },
        cancellationType() {
            this.orderStatusTransitionItem.status = this.cancellationType;
        } 
    },
    created() {
        this.setInitialOrderStatusTransition()
        this.getPurchaseAgreementRejectOrderReasons()
        this.getCoursesRejectOrderReasons()
        this.getCancelledByProviderReasons()
    },
    methods: {
        getCancellationReasons() {
            if (this.cancellationType == 'CancelledProvider') {
                return this.providerCancellationReasons
            }
            return this.isPurchaseAgreementOrder ? this.purchaseAgreementCancellationReasons : this.courseCancellationReasons
        },
        close() {
            this.$emit('close')
        },
        setCancellationReason(newValue) {
            this.orderStatusTransitionItem.cancellationReason = newValue
        },
        submitHandler() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                if (this.cancellationType == 'CancelledProvider') {
                    this.orderStatusTransitionItem.providerCancellationReason = this.orderStatusTransitionItem.cancellationReason;
                    delete this.orderStatusTransitionItem.cancellationReason
                }
                this.$bus.emit('update-order-status-transition', this.orderStatusTransitionItem)
                this.$emit('submit')
            }
        },
        setInitialOrderStatusTransition() {
            this.orderStatusTransitionItem = this.getEmptyOrderStatusTransition()
        },
        getEmptyOrderStatusTransition() {
            return {
                status: this.cancellationType,
                cancellationReason: null,
                message: null
            }
        },
        async getPurchaseAgreementRejectOrderReasons() {
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_ORDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'PurchaseAgreementCancellationReason',
                    null,
                    null,
                    false
                )
                if (!response) return
                
                this.purchaseAgreementCancellationReasons = response

            } catch (error) {
                console.error(
                    'Something went wrong while retrieving cancellation reason enum list',
                    error
                )
            }
        },
        async getCoursesRejectOrderReasons() {
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_ORDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'CourseCancellationReason',
                    null,
                    null,
                    false
                )
                if (!response) return
                
                this.courseCancellationReasons = response

            } catch (error) {
                console.error(
                    'Something went wrong while retrieving cancellation reason enum list',
                    error
                )
            }
        },
        async getCancelledByProviderReasons(){
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_ORDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'ProviderCancellationReason',
                    null,
                    null,
                    false
                )
                if (!response) return
                
                this.providerCancellationReasons = response

            } catch (error) {
                console.error(
                    'Something went wrong while retrieving cancellation reason enum list',
                    error
                )
            }
        }
    }
}
</script>
